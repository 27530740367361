import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInterface } from "../adapters/ApiSchema";

interface userState {
  user: UserInterface;
}

const initialState: userState = {
  user: {
    AccountType: "",
    CanChangePassword: false,
    CanEditProfile: false,
    DisplayName: "",
    Email: "",
    ExternalPasswordChangeUrl: "",
    ExternalProfileUrl: "",
    FirstName: "",
    HasDevices: false,
    HasExternalPasswordChangeUrl: false,
    HasExternalProfileUrl: false,
    HasJobDescription: false,
    HasStudentContactInfo: false,
    HasStudentDevices: false,
    HasStudentInfo: false,
    IsImperator: false,
    JobDescription: "",
    LastName: "",
    MyDeviceCount: 0,
    ObjectID: "",
    PhotoUrl: "",
    StudentContactCount: 0,
    StudentCount: 0,
    StudentDeviceCount: 0,
    StudentIds: [],
    UserPrincipalName: "",
    dcpsAUPAcceptanceDate: "",
    dcpsID: "",
    dcpsType: "",
    extranetGUID: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<{ user: UserInterface }>) {
      // console.log('userSlice', action.payload.user);
      // mutating state is a trigger
      Object.assign(state, {
        user: action.payload.user,
      });
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
