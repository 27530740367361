// file purpose: hold very high level sugar type code which is reusable nearly anywhere
// other than mostly universal code (like syntax sugar libs, and fable) nothing should be imported into this file

import * as Option from './adapters/fable/Option'

export function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}

export const addClasses = (classes: string[], className?: string) => {
    if (!!className) {
        return [className, ...classes].join(' ');
    }
    let warnings = classes.filter(v => v.includes("."))
    if (warnings.length > 0) {
        console.warn('dot in className(s)', warnings)
    }
    return classes.join(' ');
}

export const tryUpdateByKey = <T extends { id: string }>(items: T[], key: T['id'], f: System.Func1<T, T>) => items.map(x => x.id === key ? f(x) : x);

export const forceTryGetByName = <T extends object | null>(item: T, key: string, requiredType?: string): unknown => {
    if (item && key in (item as any)) {
        let value: unknown = (item as any)[key];
        let t = value && typeof (value);
        if (requiredType && t && t !== requiredType) throw new Error(`typeof value.${key} was '${t}' instead of '${requiredType}'`);
        return
    }
    return undefined;
};

export const forceSetByName = <T extends object | null>(item: T, key: string, value: unknown, failOnExists?: boolean) => {
    if (!item) return;
    if (key in item && failOnExists) throw new Error(`Property ${key} already exists`);
    (item as any)[key] = value;
}

export const addComponentClasses = <T>(props: T & { className?: string }, classes: string[]): T => {
    if (Object.keys(props).includes("className")) {
        let result = Object.assign({}, props, addClasses(classes, props.className));
        console.log('adding comp classes', props.className, classes, result);
        return result;
    } else {
        let result = Object.assign({}, props, { className: addClasses(classes) });
        return result;
    }
};


export function equalsCI(a?: string, b?: string) {
    return typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b;
}
export function equalsAnyCI(a: string, items: string[]) {
    return items.find(x => equalsCI(x, a)) !== undefined;
}

// work around for Array.isArray returning any[] instead of unknown[]
export const isArray = (x: unknown): x is unknown[] => Array.isArray(x);

// this duplicates similar functionality in Util.ts
// https://stackoverflow.com/a/32538867/57883
export function isIterable(obj: any): obj is Iterable<unknown> {
    if (obj === undefined || obj === null) {
        return false;
    }
    return typeof obj?.[Symbol.iterator] === 'function'; // typeof Symbol.iterator in  === 'function';
}

// https://stackoverflow.com/questions/4700226/truncating-text-and-append-an-ellipsis-using-javascript
export function truncateString(input: string, maxLength: number) {
    if (maxLength < 4) return '...';
    if (input && input.length > maxLength) {
        return `${input.substring(0, maxLength - 3)}...`;
    } else return input;
}

// export default { addClasses }
// export interface Reusable {
//     addClasses: addClasses; // (props:{}, classes: string[]) : {};
// }
// type ResultBase = { state: null }
type ResultOk<T> = { state: "ok"; value: T }
type ResultError<TError> = { state: "error"; error: TError }

// https://github.com/fable-compiler/Fable/blob/main/src/fable-library/Types.ts
// fable uses this internally:
// export type Result<T> = { tag: "ok"; value: T } | { tag: "error"; error: string };
export type Result<T, TError> = ResultOk<T> | ResultError<TError>

export function resultOk<T, TError>(value: T): Result<T, TError> { return { state: "ok", value }; }
export function resultError<T, TError>(error: TError): Result<T, TError> { return { state: "error", error }; }

export function isOkResult<T, TError>(value: Result<T, TError>): value is ResultOk<T> {
    // assuming this is a good test
    return value.state === "ok";
}

export function iTry<T>(f: System.Func<T>): Result<T, unknown> {
    try {
        return resultOk(f());
    } catch (ex) {
        return resultError(ex);
    }
};
// export namespace Option {

//     // reworking to fit sample code from https://stackoverflow.com/questions/71167632/how-is-the-maybe-monad-useful-in-typescript
//     interface None { type: "none" }
//     interface Some<T> { type: "some"; value: T }

//     export type Option<T> = Some<T> | None

//     export const None = (): None => ({ type: "none" });
//     export const Some = <T>(value: T): Some<T> => ({
//         type: "some",
//         value
//     })
// }


// ({
//     type: None
// })
// export class Option<T>{
//     readonly item: OptionNone | OptionSome<T>;


//     constructor();
//     constructor(value?: T) {
//         if (value !== undefined)
//             this.item = { state: "some", value: value }
//         else
//             this.item = { state: "none" }
//     }

//     get value() {
//         if(this.item.state === "some")
//             return this.item.value;
//         return undefined;
//     }

//     get isSome() {
//         return this.item.state === "some";
//     }

//     map<TDest>(f:(item:T) => TDest) {
//         if(this.isSome)
//             return new Option(f(this.item.value))
//     }
// }
// export module Option {
//     export function map()
// }

export const tryF = <TResult>(f: (() => TResult)): Result<TResult, unknown> => {
    try {
        let value = f();
        return { state: "ok", value };
    } catch (ex) {
        return { state: "error", error: ex };
    }
}

export const tryGetOk = <TResult>(r: Result<TResult, unknown>): (TResult | undefined) => {
    switch (r.state) {
        case "ok":
            return r.value;
        default:
            return undefined;
    }
}

// TODO: make this humanize pascal and camel case
export const humanize = (value: string): string => {
    if (!value) return value;
    return value.replace(/_/g, ' ',);
}

export const addQueryParam = (url: string, key: string, value: string) => {
    var eValue = encodeURIComponent(value);
    if (url.includes('?'))
        return `${url}&${key}=${eValue}`;
    return `${url}?${key}=${eValue}`;
};

export const addQueryParamIfValue = (url: string, key: string, value: string | undefined) => {
    if (!!value) return addQueryParam(url, key, value);
    return url;
}

export const addQueryParams = (url: string, values: { key: string, value: string }[]) => {
    let query = values.filter(x => !!x.value).map(x => `${x.key}=${encodeURIComponent(x.value)}`).join('&')
    if (url.includes('?'))
        return `${url}&${query}`;
    return `${url}?${query}`;
}

// export function* choose<TSrc,TDest>(items:Iterable<TSrc>, f: (item:TSrc) => Option<TDest> ):Iterable<TDest>{
export function* choose<TSrc, TDest>(items: TSrc[], f: (item: TSrc) => Option.Option<TDest>) {
    for (const v of items) {
        let next = Option.unwrap(f(v));
        if (!!next)
            yield next;
    }
}

// based on https://mui.com/x/react-date-pickers/lifecycle/#server-interaction
export function debounce<T>(func: (arg: T) => void, wait = 500) {
    let timeout: NodeJS.Timeout;
    function debounced(arg: T) {
        const later = () => {
            func(arg);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }

    debounced.clear = () => {
        clearTimeout(timeout);
    };

    return debounced;
}

// https://dev.to/jorensm/debounce-function-with-a-return-value-using-promises-1i14
export function debounceAsync<T, TResult>(func: ((arg: T) => TResult) | ((arg: T) => Promise<TResult>), wait = 500): (arg: T) => Promise<TResult> {
    let timer: NodeJS.Timeout;


    return (...args) => {
        return new Promise((resolve, reject) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                try {
                    let output = func(...args);
                    // testing suggests this automatically recurses Promise<T>
                    resolve(output);
                } catch (err) {
                    reject(err);
                }
            }, wait);
        })
    }
}

// https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
function onlyUnique<T>(value: T, index: number, array: T[]) {
    return array.indexOf(value) === index;
}

export const distinct = <T>(items: T[]) => items.filter(onlyUnique);

export type DistinctAlgoType =
    'FindIndex' // not performant for large sets, probably better off building a set of seen keys
    | 'BuildSet'

const distinctByKeySet = <T, TKey extends keyof T>(fKey: (item: T) => T[TKey]) => {
    let s = new Set<T[TKey]>();
    return (item: T) => {
        let keyValue = fKey(item);
        if (s.has(keyValue))
            return false;
        s.add(keyValue);
        return true;
    };
}

export function distinctByKey<T, TKey extends keyof T>(items: T[], algo: DistinctAlgoType, fKey: (item: T) => T[TKey]): T[] {
    if (items.length < 1) return [];
    switch (algo) {
        case 'FindIndex':
            return items.filter((item, i) => items.findIndex(x => fKey(x) === fKey(item)) === i);
        case 'BuildSet':
            let filter = distinctByKeySet<T, TKey>(fKey);
            return items.filter(filter);
    }
}

// not performant for large sets, probably better off building a set of seen keys
export function distinctByKeyName<T, TKey extends keyof T>(items: T[], algo: DistinctAlgoType, fKey: TKey): T[] {
    if (items.length < 1) return [];
    switch (algo) {
        case 'FindIndex':
            return items.filter((item, i) => items.findIndex(x => x[fKey] === item[fKey]) === i);
        case 'BuildSet':
            let filter = distinctByKeySet<T, TKey>(x => x[fKey]);
            return items.filter(filter);
    }
}

type MaybeString = string | null | undefined
export namespace System {
    export type Action = () => void;
    export type Action1<T1> = (x1: T1) => void;
    // same as what is in react, but we don't have to take a dependency on react
    export type SetStateAction<S> = S | ((prevState: S) => S);
    export type SetStateFunc<T> = System.Action1<SetStateAction<T>>
    export type PropWrapper<T> = [T, SetStateFunc<T>]

    export type Func<T> = () => T;
    export type Func1<T1, T> = (x1: T1) => T
    export type Func2<T1, T2, T> = (x1: T1, x2: T2) => T
    export type Func3<T1, T2, T> = (x1: T1) => (x2: T2) => T

    export function exception(message: string) { return { msg: message } }
    // enables ternary throws
    // https://stackoverflow.com/questions/40573516/typescript-2-throwing-in-ternary-conditional-operator-expression
    // https://github.com/microsoft/TypeScript/issues/18535
    export function throwException(message: string) { throw exception(message); }

    export abstract class String {
        public static isValueString = (input: MaybeString): input is string => !!input && input.trim() !== '';
        public static isNullOrWhitespace = (input: MaybeString) => !input || !input.trim();

        // handles null, undefined, NaN, and empty String
        public static isNullOrEmpty = (input: MaybeString) => !input || input === '';

        // assertions don't work with arrow functions
        // https://github.com/microsoft/TypeScript/issues/34523
        public static failNullOrEmpty(title: string, value: MaybeString): asserts value is string {
            return String.isNullOrEmpty(value) ? throwException(title) : undefined;
        }

        public static prependIfValue = (value: MaybeString, before: string, space?: boolean) => String.isValueString(value) ? before + (space === true ? ' ' : '') + value : value;
        public static appendIfValue = (value: MaybeString, after: string, space?: boolean) => String.isValueString(value) ? value + (space === true ? ' ' : '') + after : value;
        public static surroundIfValue = (value: MaybeString, before: string, after: string, space?: boolean) => String.appendIfValue(String.prependIfValue(value, before, space), after, space);
        public static quotedIfValue = (value: MaybeString) => String.surroundIfValue(value, '\'', '\'');

        public static valueStringPredicate = (f: (delimiter: string, value: MaybeString) => boolean) => (delimiter: string, value: MaybeString) => {
            String.failNullOrEmpty('delimiter', delimiter);
            return f(delimiter, value);
        }

        // making value a MaybeString broadens return values causing way more noise
        public static afterOrSelf = (delimiter: string, value: string): string => {
            String.failNullOrEmpty('delimiter', delimiter);
            if (String.isValueString(value)) {
                var i = value.indexOf(delimiter);
                if (i < 0) return value;
                return value.slice(i + delimiter.length);
            }
            return value;
        }

        public static contains = (delimiter: string, value: MaybeString): boolean => {
            if (String.isNullOrEmpty(delimiter)) throw new Error("Delimiter must be a value");
            if (String.isValueString(value)) {
                return value.includes(delimiter);
            }
            return false;
        }
        public static containsCI = (delimiter: string, value: MaybeString): boolean => {
            if (String.isNullOrEmpty(delimiter)) throw new Error("Delimiter must be a value");
            if (String.isValueString(value)) {
                return value.toLowerCase().includes(delimiter.toLowerCase());
            }
            return false;
        }
    }
}
// declare module String {
//     export function isNullOrWhitespace = (input:string | null | undefined) => !input || !input.trim();
// }
// export function afterOrSelf(delimiter:string, value: string): string {
//     return !input || !input.trim();
// }