// adapter for window stuff that needs special handling in special cases

import { IObservable, IObservableValue, Observable, ObservableValue, Unsubscriber } from "../components/BObservable";

import { IDisposable, addDisposal } from "./fable/Util";

export const scrollToTop = (function () {

    let reportedFailure = false;
    return () => {

        if ('scrollTo' in window) {
            window.scrollTo(0, 0);
        } else {
            if (!reportedFailure) {
                console.warn('scrollTo not found');
                reportedFailure = true;
            }
        }
    }
})();

// we have a situation where iphone dcps app is doing nothing when links are clicked
export function windowOpen(url: string, target?: string | undefined, features?: string | undefined) {
    // window.open(url, target, features);
    // TODO: log this to SEQ
    const ua = window.navigator.userAgent;
    if (ua.includes("AppleWebKit") && (ua.includes("iPhone") || ua.includes("iPad")) && !ua.includes("Version")) {
        console.warn("Using apple web kit workaround");
        window.location.href = url;
        return;

    }
    try {
        // checking window.open's return value for null failed to test on localhost
        window.open(url, target, features);
    } catch (ex) {
        // TODO: log this to SEQ
        console.error("Failed to call window.open");
        window.location.href = url;
    }
}

export function getEventListenerObservable(eventType: string, debug?: boolean): Observable<unknown> {
    let ob = new Observable(debug);
    let f = (e: unknown) => ob.Next(e);
    window.addEventListener(eventType, f);

    (ob as unknown as IDisposable).Dispose = () => window.removeEventListener(eventType, f);

    return ob;
}

export function addHashChangeListener(f: (this: Window, ev: HashChangeEvent) => void, option: boolean): Unsubscriber {
    window.addEventListener('hashchange', f, option);

    let unSub: Unsubscriber =
        () => {
            window.removeEventListener('hashchange', f);
        };
    return unSub;
}


// working around eslint global error
// https://stackoverflow.com/questions/54283213/why-do-i-get-unexpected-use-of-location-no-restricted-globals
// react's useLocation seems to work fine too
export function tryGetLocation() {
    return 'location' in window ? window.location : undefined;
}

// https://stackoverflow.com/questions/13304471/javascript-get-code-to-run-every-minute
export function makeTimer(f: Function, durationSeconds: number, diagTitle?: string): Unsubscriber {
    console.log('making timer', diagTitle);
    var laterId = 0;

    // let f2 = diagTitle && diagTitle.length > 0 ? () => {
    //     console.log(diagTitle + ':' + laterId);
    // } : f;
    // laterId = setInterval(f2, durationSeconds * 1000);
    laterId = setInterval(f, durationSeconds * 1000);

    return () => clearInterval(laterId);
}

export function tryObserveLocation(): (IObservableValue<Location | undefined> & IDisposable) {
    // console.log('ObserveLocation setting up');
    let initial = tryGetLocation();
    let ov = new ObservableValue<Location | undefined>(initial, false);
    let update = () => {
        // console.log('observeLocation update');
        let nextLocation = tryGetLocation();
        // console.log('observeLocation!', JSON.stringify({ path: nextLocation && nextLocation.pathname, hash: nextLocation && nextLocation.hash }));
        ov.Next(nextLocation);
    }

    // not sure the options flag is set the way we want it, test it
    let unSub = addHashChangeListener(_ => {
        // console.log('HashChangeListener')
        update();
    }, true);

    let timer = makeTimer(update, 2, "ObserveLocation");

    addDisposal(ov, unSub);
    addDisposal(ov, timer)

    // phRef = ov.Refresh;
    return ov;
}

export function isWindowBottom() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + Math.ceil(window.scrollY + 1);
    return windowBottom >= docHeight;
}

export function getComputedStylePropertyValue(element: HTMLElement, styleProp: string) {
    let computedStyle = element && 'getComputedStyle' in window ? window.getComputedStyle(element) : undefined;
    if (computedStyle === undefined) return;

    return computedStyle.getPropertyValue(styleProp);
}


export type KnownMedia =
    | 'isSmallDevice'
    | 'isDark'

export function queryKnownMatchMedia(knownMedia: KnownMedia) {
    switch (knownMedia) {
        case "isSmallDevice": // (useRefHeightMeasure): for elements that can react automatically to size changes see: https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
            return matchMedia('(max-width: 600px)');
        case "isDark":
            return matchMedia('(prefers-color-scheme: dark)');
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
export function matchMedia(mediaQuery: string): boolean | undefined {
    return "matchMedia" in window ? window.matchMedia(mediaQuery).matches : undefined;
}