import React, { useCallback, useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";

import { Link } from "@fluentui/react/lib/Link";
import { PrimaryButton } from "@fluentui/react/lib/Button";

import {
  DocumentCard,
  DocumentCardType,
  IDocumentCardStyles,
} from "@fluentui/react/lib/DocumentCard";

import { Spinner, SpinnerSize } from "@fluentui/react";
import { IconButton, Tooltip, Badge } from "@mui/material";
import {
  Refresh as RefreshIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";

import { Drawer } from "@mui/material";

import { useTranslation } from "react-i18next";
import { ActionItemResponse } from "../../Schema";
import { ServerErrorResponse, isServerErrorResponse } from "../../adapters/ApiSchema";
import { actionItemRemote } from "../ActionItemsWrapper";
import { windowOpen } from "../../adapters/windowUtils";
// import { actionItemObservable } from "../ActionItemsWrapper";


require("../../style/headerStyle.scss");

const AppNotifications = () => {
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [hasNotifications, setHasNotifications] = useState<boolean>(false);
  const [notificationItemsCount, setNotificationItemsCount] = useState<number>(0);

  const [userActionItems, setUserActionItems] = useState<undefined | ActionItemResponse[]>(undefined);

  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const { t } = useTranslation();

  // use callback so this function doesn't get regenerated unless the whole component does
  const onActionItems = useCallback(function (items: ActionItemResponse[] | ServerErrorResponse | undefined) {
    if (!!items) {
      if (!isServerErrorResponse(items)) {
        // console.log('appNotifications!')

        setUserActionItems(items);
        setHasNotifications(items.length > 0);
        setNotificationItemsCount(items.length);
      }
      setShowSpinner(false);
    }

  }, [setUserActionItems, setHasNotifications, setNotificationItemsCount, setShowSpinner])

  useEffect(() => {
    let dispose = actionItemRemote.Subscribe(new WeakRef(onActionItems));
    return dispose;
  }, [onActionItems]);

  useEffect(() => {
    if (showSpinner && actionItemRemote.value && !isServerErrorResponse(actionItemRemote.value)) {
      onActionItems(actionItemRemote.value);
    }
  });

  try {

    // const dispatch = useDispatch();
    const cardStyles: IDocumentCardStyles = {
      root: { border: "none", padding: "5px", minHeight: "140px" },
    };

    const openActionItem = (_item: string, e: string | undefined) => {
      windowOpen(_item, e, "");
    };

    const noNotifications = t("Nav.No Notification");
    const notificationTranslate = t("Nav.Notifications");


    const openActionPanel = () => {
      setShowPanel(!showPanel);
    };

    const _hidePanel = () => {
      setShowPanel(false);
    };

    var _actionsPanel = (
      <Drawer className="Notifications_AppNotifications_actionsPanel" anchor="right" open={showPanel} onClose={_hidePanel} data-file="Notifications">
        <div style={{ padding: "10px", width: "100%", textAlign: "right" }}>
          <h3 style={{ textAlign: "left" }}>{t("Nav.My Notifications")}</h3>
          <PrimaryButton
            className="fluentUI primaryButton"
            onClick={() => actionItemRemote.Refresh()}
            style={{ padding: 5, margin: 5 }}
            color="primary"
          >
            <RefreshIcon />
            {t("Nav.Refresh")}
          </PrimaryButton>
        </div>
        {hasNotifications && (userActionItems || []).map((item: ActionItemResponse, index: number) => (
          <React.Fragment key={index}>
            <div data-is-focusable={true}
              role="listitem"
              aria-label={item.Title}
              className="ovCardSection Notifications_AppNotifications_actionsPanel_userActionItems"
              key={"id" + index}>
              <DocumentCard
                className="fluentUi documentCard"
                type={DocumentCardType.normal}
                styles={cardStyles}
                key={item.Description}
              >
                <div className="ovCardTitle">{item.Title}</div>
                <div className="ovCardDescription">
                  {item.Description}
                </div>
                <div className="ovCardLink">
                  <Link
                    onClick={() => openActionItem(item.ActionUrl, item.LaunchType)}
                  >
                    {item.ActionTitle}
                  </Link>
                </div>
              </DocumentCard>
            </div>
          </React.Fragment>
        ))}
        {!hasNotifications && (
          <div key="notification" style={{ padding: "10px" }}>
            {noNotifications}
          </div>
        )}
        <div
          className={showSpinner ? "" : "ovHidden"}
          style={{ padding: 20, margin: 20 }}
        >
          <Spinner className="fluentUI spinner" label="Loading..." size={SpinnerSize.large}></Spinner>
        </div>
      </Drawer>
    );

    var _actionItems = (
      <>
        <IconButton
          aria-label="Notifications"
          style={{ color: "#fff" }}
          onClick={openActionPanel}
        >
          <Badge badgeContent={notificationItemsCount} color="secondary">
            <Tooltip title={notificationTranslate}>
              <div>
                <NotificationsIcon />
              </div>
            </Tooltip>
          </Badge>
        </IconButton>
      </>
    );

    var _result = (
      <>
        {_actionItems}
        {_actionsPanel}
      </>
    );

    return _result;
  } catch (ex) {
    console.error(ex);
    return <div></div>;
  }
};

export default AppNotifications;

