import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { classes, Root } from "../pages/Home/CDS"
import { Avatar, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, IconButtonProps, Popover, Skeleton, Typography } from "@mui/material"
import { red } from "@mui/material/colors"

import { styled } from '@mui/material/styles';

import {
    Share as ShareIcon,
    ExpandMore as ExpandMoreIcon,
    ContentCopy as ContentCopyIcon,
    Check as CheckIcon,
    ThumbUp as ThumbUpIcon,
    ThumbUpOutlined as ThumbUpOIcon
} from '@mui/icons-material';

// see also https://www.geeksforgeeks.org/lazy-loading-in-react-and-how-to-implement-it/
import LazyLoad from 'react-lazyload';
import { windowOpen } from "../adapters/windowUtils"
import React from "react"
import { LikeInfo, ServerPostItem } from "../adapters/FeedAdapter"
import { useResizeListener } from "../util/ReactReusable";

export type PostItemNeeds = {
    isSmallDevice: boolean
    onLikeUnlikeClick: (itemId: string) => Promise<void> | undefined
    onLoadRequest: Function
}
// exported only for mvu feed
export type PostItemProps = PostItemNeeds & {
    postItem: ServerPostItem
}

const THUMB_HEIGHT = '100%';
const MAX_HEIGHT = 600;

interface ThumbnailProps extends React.ImgHTMLAttributes<HTMLImageElement> { loaded: boolean, isSmallDevice: boolean }

const CardThumbnail = styled((props: ThumbnailProps) => {
    const { loaded, isSmallDevice, ...other } = props;
    return (
        <LazyLoad once data-file="CDS" height={THUMB_HEIGHT} offset={800} placeholder={<Skeleton sx={{ height: "100%" }} animation="wave" variant="rectangular" />}>
            <img alt="author thumbnail" {...other} loading="lazy" style={{ maxWidth: isSmallDevice ? 315 : undefined }} />
        </LazyLoad>
    )
})(({ theme, loaded }) => ({
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    objectFit: 'contain',
    height: THUMB_HEIGHT,
    [theme.breakpoints.down('md')]: {
        height: '100%',
    },
    maxHeight: MAX_HEIGHT,
    opacity: !loaded ? 0 : 1,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.enteringScreen,
    }),
}));

interface ShareProps extends IconButtonProps { anchorEl: any, onOpen: any, onClose: any }

const Share = styled((props: ShareProps) => {
    const { anchorEl, onOpen, onClose, onClick, ...other } = props;
    return (<React.Fragment>
        <IconButton
            {...other} data-file="CDS"
            onClick={onOpen}>
            <ShareIcon />
        </IconButton>
        <Popover
            id={Boolean(anchorEl) ? 'share-popover' : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {props.children}
        </Popover>
    </React.Fragment>);
})(({ theme }) => ({

}));

interface LikeProps extends IconButtonProps { id: string, onLoadRequest: Function, likeInfo: LikeInfo | undefined }

// https://bobbyhadz.com/blog/react-check-if-element-in-viewport
// https://spacejelly.dev/posts/how-to-trigger-a-function-when-scrolling-to-an-element-in-react-intersection-observer
// https://stackoverflow.com/questions/58328428/trigger-event-when-a-each-div-has-been-scrolled-into-view-and-update-state
// https://dev.to/jmalvarez/check-if-an-element-is-visible-with-react-hooks-27h8
function useIsInViewport<T extends Element>(ref: React.MutableRefObject<T | null>) {

    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        if (ref.current != null)
            observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}
// this may be better off lifted and split into another component that allows disposal of the viewport stuff once loaded
const Like = styled((props: LikeProps) => {
    const { id, onClick, disabled, onLoadRequest, likeInfo, ...other } = props;
    const loaded = props.likeInfo !== undefined;
    const userLiked = props.likeInfo && props.likeInfo.iLike === true;

    let likeRef = useRef<HTMLDivElement>(null)
    // on scroll into view, triggering like loading options:
    const isInViewport = useIsInViewport<HTMLDivElement>(likeRef);

    useEffect(() => {
        if (loaded) return;
        if (!isInViewport) return;
        // console.info('loading like info for', id);
        onLoadRequest();
    }, [loaded, onLoadRequest, isInViewport]);
    // console.log('rendering like:', props.id, props.likeInfo);

    return (<React.Fragment>
        <div ref={likeRef} key={props.id} title={loaded && !disabled ? (userLiked ? 'Unlike Post' : 'Like Post') : 'Likes disabled or failed to load'}>

            <IconButton
                {...other} data-file="CDS"
                disabled={disabled || !loaded}
                onClick={props.likeInfo ? props.onClick : () => { }}>
                {
                    userLiked ? <ThumbUpIcon /> : <ThumbUpOIcon />
                }
                <div>{(props.likeInfo && props.likeInfo.likes) ?? ''}</div>
            </IconButton>
            {props.children}
        </div>
    </React.Fragment>);
})(({ theme }) => ({

}));

interface ExpandMoreProps extends IconButtonProps { expand: boolean; }
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return (<IconButton {...other} >
        <ExpandMoreIcon />
    </IconButton>);
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto !important",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

// exported only for mvu feed
export const PostItem = (props: PostItemProps) => {

    // Used for details flyout
    const [expanded, setExpanded] = useState(false);

    // Used for fading in thumbnails when they are loaded
    const [thumbLoaded, setThumbLoaded] = useState<boolean>(false);

    // Used for favorite button
    const [likeUnlikeInFlight, setLikeUnlikeInFlight] = useState<boolean>(false); //TODO replace with backend call

    // Used for pop out of share button
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [copied, setCopied] = useState<boolean>(false);

    let postItem = props.postItem;

    const handleCopy = () => {
        navigator.clipboard.writeText(postItem.link);
        setCopied(true);
    }
    const handleOpenShare = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
        setCopied(false);
    }


    // Collapse or expand description to properly use space
    // consider lifting the window parts of this into windowUtils
    useResizeListener(() => {
        if (window.innerWidth < 700 && expanded) {
            setExpanded(false);
        }
    });

    const onLikeUnlikeClick = useCallback(async (itemId: string) => {
        if (!postItem.likeInfo) return;
        if (likeUnlikeInFlight) return;
        // console.log('onLikeUnlikeClick');
        setLikeUnlikeInFlight(true);
        await props.onLikeUnlikeClick(itemId);
        // the below call assumes the above call blocks, instead of being async
        // console.log('fin onLikeUnlikeClick');
        setLikeUnlikeInFlight(false);
    }
        , [likeUnlikeInFlight, postItem.likeInfo, props.onLikeUnlikeClick]);

    const hasContent = postItem.summary;

    return (
        <Root data-file="CDS" data-method="PostItem">

            <Card variant="outlined" className={classes.container}>
                <CardHeader //TODO get avatar from backend
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="avatar">
                            {postItem.provider.substring(0, 1).toUpperCase()}
                        </Avatar>
                    }
                    title={postItem.provider}
                    subheader={<span title={postItem.dateTitle}>{postItem.date}</span>}
                />
                <CardThumbnail
                    className={classes.hover}
                    loaded={thumbLoaded}
                    isSmallDevice={props.isSmallDevice}
                    onLoad={() => setThumbLoaded(true)}
                    src={postItem.thumbnail}
                    onClick={() => windowOpen(postItem.link, "_blank", "")}
                />
                <CardContent>
                    <Typography variant="h6" color="text.secondary">
                        {postItem.title}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Share
                        aria-label="share"
                        anchorEl={anchorEl}
                        onOpen={handleOpenShare}
                        onClose={() => setAnchorEl(null)}
                    >
                        {!copied ? <div className={classes.share} onClick={handleCopy}>
                            <IconButton aria-label='click to copy content'>
                                <ContentCopyIcon />
                            </IconButton>
                            <Typography align='left' sx={{ p: 2, color: 'currentcolor' }}>Copy Link</Typography>
                        </div>
                            :
                            <div className={`${classes.share} ${classes.sharePostCopy}`}>
                                <CheckIcon sx={{ fill: 'white' }} />
                                <Typography align='left' sx={{ p: 2 }}>Link Copied</Typography>
                            </div>}

                    </Share>
                    {/* {props.likeInfo == undefined && !props.likeLoading ? <img src="javascript:" loading="lazy" /> : <div />} */}
                    <Like
                        aria-label="like"
                        id={postItem.id}
                        likeInfo={postItem.likeInfo}
                        onClick={likeUnlikeInFlight ? () => { } : () => onLikeUnlikeClick(postItem.id)}
                        onLoadRequest={props.onLoadRequest}
                    >
                    </Like>
                    {hasContent && <ExpandMore
                        expand={expanded}
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    />}
                </CardActions>
                {hasContent && <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography padding="0 10px" align="justify" variant="body1" sx={{ wordWrap: "anywhere" }} >
                            {postItem.summary}
                        </Typography>
                        <div className={classes.blur} />
                        <Typography sx={{ textAlign: "center", display: "block", cursor: "pointer" }} variant="button" onClick={() => windowOpen(postItem.link, "_blank", "")}>
                            Click to read more
                        </Typography>
                    </CardContent>
                </Collapse>}
            </Card>
        </Root>
    );
}