// cSpell:ignore Flickr 
import * as React from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { useEffect, useState } from "react";

// import * as Mui from "@mui/icons-material";
import * as MuIcon from "@mui/icons-material";

import {
  Home as HomeIcon,
  HelpOutline as HelpIcon,
  LocalLibrary as LocalLibraryIcon,
  Apps as AppsIcon,
  Link as LinkIcon,
  LocationCity as LocationCityIcon,
  School as SchoolIcon,
  Contacts as ContactsIcon,
  Devices as DevicesIcon,
  DevicesOther as DevicesOtherIcon,
} from "@mui/icons-material";

import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RootState } from "../../store";

import {
  ToggleButton,
  Typography,
  TypographyProps,
  ToggleButtonGroup,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { getFromLS } from "../../util/storage";
import defaultStore from "../../util/Globale";
import { MenuItem } from "../../store/menu-slice";
import { UserInterface } from "../../adapters/ApiSchema";
import { System, equalsCI } from "../../Reusable";
import { queryKnownMatchMedia, windowOpen } from "../../adapters/windowUtils";
import { useResizeListener } from "../../util/ReactReusable";

type LeftNavMenuItem = {
  name: string
  icon: JSX.Element | undefined
  aria: string
  link: string
  weight?: number
  isDynamic?: boolean
}

const PREFIX = 'LeftNav';

const classes = {
  social: `${PREFIX}-social`,
  icons: `${PREFIX}-icons`,
  close: `${PREFIX}-close`,
  open: `${PREFIX}-open`,
  toggleSet: `${PREFIX}-toggleSet`,
  toggleOpen: `${PREFIX}-toggleOpen`,
  toggleClosed: `${PREFIX}-toggleClosed`,
  button: `${PREFIX}-button`,
  buttonClosed: `${PREFIX}-buttonClosed`,
  iconLabel: `${PREFIX}-iconLabel`
};

// see: https://github.com/mui/material-ui/issues/13196
const TypoSpan = (props: any) => {
  let { component, ...myProps } = props
  return (
    <Typography component={component ?? "span"} {...myProps} />
  );
};

// was styled(Typography), but that was producing a <p> tag
const Root = styled(TypoSpan)((
  {
    theme: theme
  }
) => ({
  [`& .${classes.social}`]: {
    position: "absolute",
    bottom: 0,
    cursor: "pointer",
    width: "100%",
    padding: 0,
  },

  [`& .${classes.icons}`]: {
    color: theme.palette.primary.dark,
    fontSize: 30,
    borderRadius: 0,
    textAlign: "center",
    paddingBottom: 7,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: "1px transparent",
    },
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.close}`]: {
    flexDirection: "column",
    textAlign: "center",
  },

  [`& .${classes.open}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto",
    overflow: "hidden",
    padding: "0 10px",
  },

  [`&.${classes.toggleSet}`]: {
    marginTop: 59, //width of toolbar
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    "& .MuiToggleButtonGroup-grouped": {
      color: "inherit"
    }
  },

  [`& .${classes.toggleOpen}`]: {
    margin: `${TOP}px 0 0 0`,
  },

  [`& .${classes.toggleClosed}`]: {
    margin: `${TOP}px 0 0 0`,
  },

  [`& .${classes.button}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    width: "100%",
    padding: 15,
    marginBottom: 10,
    minWidth: "unset",
    border: "unset",
    borderRadius: "0 !important",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      //color: theme.palette.primary.main,
      border: "1px transparent",
    },
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.buttonClosed}`]: {
    borderRadius: "0px !important",
    padding: "15px 0px 15px 16px",
    //marginBottom: 0,
  },

  [`& .${classes.iconLabel}`]: {
    paddingLeft: 10,
    alignSelf: "center",
    marginRight: "auto",
    // whiteSpace: 'nowrap'
  }
}));

const TOP = 59; // Location of top of leftNav (do not change)
const TOG_GAP = 10; // Desired gap of toggle buttons
const APPROX_ICN_HEIGHT = 53; //Used to decide if we should hide the icons

let filterMenu = (forceNav: boolean, user?: UserInterface) => (menuItem: MenuItem) => {
  if (forceNav) return true;
  if (menuItem.Enabled === false) return false;
  switch (menuItem.Name) {
    case "My Apps":
      return user?.AccountType === "Internal";
    case "District Links":
      return false;
  }
  // name: t("Nav.My Apps"),
  // user?.AccountType === "Internal" || forceNav
  return true;
}

let homeItem = (t: (key: string) => string): LeftNavMenuItem => ({
  // name: "Home",
  name: t("Nav.Home"),
  link: "/home",
  aria: "redirect to home screen",
  icon: <HomeIcon />,
  weight: -9999
});

// home
// my info/ my student info
// my student contact info
// my devices
// my student devices
function* genStaticItems(forceNav: boolean, user: UserInterface | undefined, t: (key: string) => string): Generator<LeftNavMenuItem> {
  yield homeItem(t);

  // if (user?.AccountType === "Internal" || forceNav)
  //   yield {
  //     name: t("Nav.My Apps"),
  //     link: "/myApps",
  //     aria: "redirect to my apps page",
  //     icon: <AppsIcon />,
  //     isDynamic: false
  //   };

  if ((user?.HasStudentInfo && user?.dcpsType === "Student") || forceNav)
    yield {
      name: t("Nav.My Info"),
      link: "/myInfo",
      aria: "redirect to my info page",
      icon: <SchoolIcon />,
      isDynamic: false
    };

  if ((user?.HasStudentInfo && user?.dcpsType !== "Student") || forceNav)
    yield {
      name: t("Nav.My Student Info"),
      link: "/myStudentInfo",
      aria: "redirect to my info page",
      icon: <SchoolIcon />,
      isDynamic: false
    };
  if (user?.HasStudentContactInfo || forceNav)
    yield {
      name: t("Nav.My Student Contact Info"),
      link: "/myStudentContactInfo",
      aria: "redirect to student contact info page",
      icon: <ContactsIcon />,
      isDynamic: false
    };
  // if (user?.HasDevices || forceNav)
  //   yield {
  //     name: t("Nav.My Devices"),
  //     link: "/myDevices",
  //     aria: "redirect to devices page",
  //     icon: <DevicesIcon />,
  //     isDynamic: false
  //   };
  if (user?.HasStudentDevices || forceNav)
    yield {
      name: t("Nav.My Student Devices"),
      link: "/myStudentDevices",
      aria: "redirect to my student devices page",
      icon: <DevicesOtherIcon />,
      isDynamic: false
    };
}

// mates dynamic and statics
let genLeftNavItems = (forceNav: boolean, user: UserInterface | undefined, t: (key: string) => string) => (menu: MenuItem[]): LeftNavMenuItem[] => {

  let filteredItems = menu.filter(filterMenu(forceNav, user)).map(menu => {
    // https://stackoverflow.com/questions/56369444/how-can-i-dynamically-load-an-icon-using-its-snake-case-name-react-material-ui
    // semi-related https://stackoverflow.com/questions/33471880/dynamic-tag-name-in-react-jsx
    const Icon = menu.Icon && (MuIcon as any)[menu.Icon] ? (MuIcon as any)[menu.Icon] : undefined;
    const ele = Icon ? <Icon /> : undefined;
    let itemLink = menu.Path;
    try {
      itemLink = menu.DeepLink ? ("/" + menu.DeepLink) : System.String.afterOrSelf("/root", System.String.afterOrSelf('/Root', menu.Path));
    } catch (ex) {
      console.error("getting link", menu, ex);
    }
    var result: LeftNavMenuItem = {
      name: menu.Name,
      // this isn't trimming /Root off anything
      // link: System.String.afterOrSelf("/root", System.String.afterOrSelf('/Root', menu.Path)),
      link: itemLink,
      aria: menu.Description,
      icon: ele,
      weight: menu.Weight,
      isDynamic: true
    };
    return result;
  });

  // TODO: look into social icons and drawer behavior
  // if (!defaultStore.isProduction) {
  //   console.log(filteredItems);
  // }
  return [
    ...genStaticItems(forceNav, user, t),
    ...filteredItems
  ].sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));

}

interface LeftNavProps {
  drawerOpen: boolean;
  menuHandlerMobile: any;
  screenSize: boolean;
}

const LeftNav: React.FunctionComponent<LeftNavProps> = ({
  drawerOpen,
  menuHandlerMobile,
  screenSize,
}: LeftNavProps) => {

  const location = useLocation();
  const [socialItems, setSocialItems] = useState<MenuItem[]>([]);
  const [showSocial, setShowSocial] = useState<boolean>(true);
  const [showText, setShowText] = useState<boolean>(drawerOpen);
  // https://stackoverflow.com/questions/62242963/get-current-language-next-i18next
  const { t, i18n } = useTranslation();
  const label = i18n.language;
  // const otherLabel = label == "en-US" ? "span" : "en-US";
  // console.log('lang', label, otherLabel);
  // const onTClick = () => i18n.changeLanguage(otherLabel);
  if (label !== "en-US") {
    console.log("the default language was ", label);
    i18n.changeLanguage('en-US');
  }

  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const forceNav = getFromLS<boolean>("forceNav") === true;

  let mql = queryKnownMatchMedia('isSmallDevice') ?? false;

  // For switching selected route in menu
  const [toggle, setToggle] = useState(
    ["", "/"].includes(location.pathname) ? "/home" : location.pathname
  );
  useEffect(() => {
    setToggle(
      ["", "/"].includes(location.pathname) ? "/home" : location.pathname
    );
  }, [location]);

  //for Navigation control
  const user = useSelector((state: RootState) => state.user.user);
  //for menu
  const menu = useSelector((state: RootState) => state.menu.menu);

  // Load Items
  useEffect(() => {
    //setSpinner(false);
    formatActivityItems(menu);
  }, [menu]);

  // Check height on initial load and hide social icons to save room
  const checkOverflow = () => {
    let overflow;
    if (!drawerOpen) {
      overflow =
        window.innerHeight - TOP <
        APPROX_ICN_HEIGHT * (socialItems.length + navItems.length);
    } else {
      overflow =
        window.innerHeight - TOP <
        (APPROX_ICN_HEIGHT + TOG_GAP) * navItems.length + APPROX_ICN_HEIGHT;
    }
    if (overflow && showSocial) {
      console.warn('social icons(drawerOpen,overflow,showSocial)', drawerOpen, overflow, showSocial);
      setShowSocial(false);
    } else if (!overflow && !showSocial) {
      setShowSocial(true);
    }
  };
  useEffect(() => {
    checkOverflow();
  }, [socialItems, drawerOpen]);
  useResizeListener(checkOverflow);

  // Add delay for showing text when drawer opens
  useEffect(() => {
    if (drawerOpen) {
      setShowText(false);
      var timeout = setTimeout(() => {
        setShowText(true);
      }, 170); //sweet spot
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setShowText(false);
      if (mql) {
        setShowSocial(false);
      }
    }
  }, [drawerOpen, mql]);

  // dynamic MUI icon import options: https://stackoverflow.com/questions/65249396/import-react-material-ui-icons-dynamically
  const navItems: LeftNavMenuItem[] =
    defaultStore.useDynamicNav ? genLeftNavItems(forceNav, user, t)(menu) :
      [
        homeItem(t),
        {
          name: t("Nav.Blended Learning"),
          link: "/blendedLearning",
          aria: "redirect to blended learning page",
          icon: <LocalLibraryIcon />,
        },
        ...(user?.AccountType === "Internal" || forceNav
          ? [
            {
              name: t("Nav.My Apps"),
              link: "/myApps",
              aria: "redirect to my apps page",
              icon: <AppsIcon />,
            },
          ]
          : []),
        {
          name: t("Nav.My Links"),
          link: "/myLinks",
          aria: "redirect to my links page",
          icon: <LinkIcon />,
        },
        {
          name: t("Nav.My Schools"),
          link: "/mySchools",
          aria: "redirect to my schools page",
          icon: <LocationCityIcon />,
        },
        ...(user?.HasStudentInfo || forceNav
          ? [
            {
              name:
                user?.dcpsType === "Student"
                  ? t("Nav.My Info")
                  : t("Nav.My Student Info"),
              link: user?.dcpsType === "Student" ? "/myInfo" : "/myStudentInfo",
              aria: "redirect to my info page",
              icon: <SchoolIcon />,
            },
          ]
          : []),
        ...(user?.HasStudentContactInfo || forceNav
          ? [
            {
              name: t("Nav.My Student Contact Info"),
              link: "/myStudentContactInfo",
              aria: "redirect to student contact info page",
              icon: <ContactsIcon />,
            },
          ]
          : []),
        ...(user?.HasDevices || forceNav
          ? [
            {
              name: t("Nav.My Devices"),
              link: "/myDevices",
              aria: "redirect to devices page",
              icon: <DevicesIcon />,
            },
          ]
          : []),
        ...(user?.HasStudentDevices || forceNav
          ? [
            {
              name: t("Nav.My Student Devices"),
              link: "/myStudentDevices",
              aria: "redirect to my student devices page",
              icon: <DevicesOtherIcon />,
            },
          ]
          : []),
        ...(showHowTo || forceNav
          ? [
            {
              name: t("Nav.How To"),
              link: "/howTo",
              aria: "redirect to how to page for employees",
              icon: <HelpIcon />,
            },
          ]
          : []),
      ];
  (window as any).navItems = navItems;

  const formatActivityItems = (navMenu: MenuItem[]) => {
    navMenu
      .map(menuItem => {
        if (equalsCI("How To", menuItem.Name) || forceNav) {
          setShowHowTo(true);
        }
        if (equalsCI("District Links", menuItem.Name) && !!menuItem.Items) {
          setSocialItems(menuItem.Items);
        }
      })
  };

  const renderWithSocialIcons = (iconName: string, socialUrl: string) => {
    const title = `${iconName}: ${socialUrl}`;
    switch (iconName) {
      case "Facebook":
        return (
          <FontAwesomeIcon
            icon={["fab", "facebook"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      case "Twitter":
        return (
          <FontAwesomeIcon
            icon={["fab", "twitter"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      case "YouTube":
        return (
          <FontAwesomeIcon
            icon={["fab", "youtube"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      case "Flickr":
        return (
          <FontAwesomeIcon
            icon={["fab", "flickr"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      case "Linkedin":
      case "LinkedIn":
        return (
          <FontAwesomeIcon
            icon={["fab", "linkedin"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      case "Instagram":
        return (
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            title={title}
            onClick={() => windowOpen(socialUrl)}
            size={"xs"}
          />
        );
      default:
        console.warn('unknown social icon', iconName);
        return;
    }
  };
  var tbgGroupCls = {
    [classes.toggleClosed]: !drawerOpen,
    [classes.toggleOpen]: drawerOpen,
  };
  return (
    <React.Fragment>
      <Root className="LeftNav_LeftNav LeftNavRoot" data-file="LeftNav">

        <ToggleButtonGroup
          id="LeftNavTBG"
          className={clsx(classes.toggleSet, tbgGroupCls)}
          orientation="vertical"
          value={toggle}
          exclusive
          color="primary"
        >
          {navItems.map((item, index) => (
            <ToggleButton
              className={clsx(classes.button, {
                [classes.buttonClosed]: !drawerOpen,
              })}
              title={item.name}
              data-weight={item.weight || '0'}
              data-is-dynamic={item.isDynamic || 'false'}
              data-link={item.link}
              value={item.link}
              key={index}
              component={Link}
              to={item.link}
              aria-label={item.aria}
              onClick={screenSize ? menuHandlerMobile : undefined}
            >
              {item.icon ? React.cloneElement(item.icon, { fill: "" }) : <div />}
              {
                <FadeTypography
                  className={classes.iconLabel}
                  variant="button"
                  loaded={showText}
                >
                  {showText ? item.name : " "}
                </FadeTypography>
              }
            </ToggleButton>
          ))}
          {/* <ToggleButton onClick={onTClick} value="translation">{i18n.language}</ToggleButton> */}
        </ToggleButtonGroup>
        {showSocial && (
          <div className={classes.social}>
            <div className={drawerOpen ? classes.open : classes.close}>
              {socialItems.map((item) => (
                <div className={classes.icons} key={item["Name"]}>
                  {renderWithSocialIcons(item["Name"], item["Url"])}
                </div>
              ))}
            </div>
          </div>
        )}
      </Root>
    </React.Fragment>
  );
};

/************************ HELPER COMPONENTS ********************/

interface FadeTypographyProps extends TypographyProps {
  loaded: boolean;
}
const FadeTypography = styled((props: FadeTypographyProps) => {
  const { loaded, ...other } = props;
  return <Root {...other} id="LeftNavStyledTypo" />;
})(({ theme, loaded }) => ({
  opacity: !loaded ? 0 : 1,
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
}));

export default LeftNav;
