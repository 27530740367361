// intent: create reusable templating for creating mvu-based stores

export type UpdateCmdResult<State, TCmd> = { newState: State, cmds: TCmd[] }

export interface UpdateArgs<State, TMsg extends Msg> { msg: TMsg, model: State };

export type Msg = {
    type: string
}

export const noUpdate = <T, TCmd>(model: T): UpdateCmdResult<T, TCmd> => { return { newState: model, cmds: [] }; };

export type UpdateHandler<TState, TMsg extends Msg, TCmd> = (ua: UpdateArgs<TState, TMsg>) => UpdateCmdResult<TState, TCmd>

export type CmdDispatch<TCmd> = (cmds: TCmd[]) => void

// export type CmdDispatchMaker<TCmd> = (dispatch:Function) => (cmds:TCmd[]) => void
export type CmdRunner<TCmd> = (dispatch: Function) => (cmd: TCmd) => void

export const makeCmdDispatch = <TCmd>(dispatch: Function, runner: CmdRunner<TCmd>) => (cmds: TCmd[]) => cmds.map(runner(dispatch))
