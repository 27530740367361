import { useEffect } from "react";
import { JsEvent } from "../adapters/Eventing";

// useEffect registers unhook listener
const useEventListener = (target: any, type: string, listener: Function, ...options: any[]) => {
    useEffect(() => {
        const targetIsRef = target.hasOwnProperty("current");
        const currentTarget = targetIsRef ? target.current : target;
        if (currentTarget)
            currentTarget.addEventListener(type, listener, ...options);
        return () => {
            if (currentTarget)
                currentTarget.removeEventListener(type, listener, ...options);
        };
    }, [target, type, listener, options]);
};

export default useEventListener;

// useEventListener(window, 'resize', () => {
//     if (window.innerWidth < 700 && expanded) {
//         setExpanded(false);
//     }
export const useResizeListener = (f: Function) => {
    useEventListener(window, 'resize', f);
}
export const useWindowScrollListener = (f: Function) => useEventListener(window, 'scroll', (e: JsEvent) => f(e));