import { configureStore } from "@reduxjs/toolkit";
import errorSlice from "./error-slice";
import menuSlice from "./menu-slice";
import studentSlice from "./student-slice";

import tokenSlice from "./token-slice";
import userSlice from "./user-slice";
import searchSlice from "./search-slice";

const store = configureStore({
  reducer: {
    error: errorSlice.reducer,
    menu: menuSlice.reducer,
    search: searchSlice.reducer,
    student: studentSlice.reducer,
    token: tokenSlice.reducer,
    user: userSlice.reducer,
  },
  // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['your/action/type'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.cmdDispatch'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
