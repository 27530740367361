import { useRef } from 'react';
import useEventListener from './ReactReusable';

const INIT = { x: 0, y: 0 };
interface TPos {
    x: number;
    y: number;
}


const MODE = {
    USE_PULL_RIGHT: "usePullRight",
    USE_SWIPE_LEFT: "useSwipeLeft",
}

const useSwipe = (mode: String, onTrigger: Function) => {
    var pStart = useRef<TPos>(INIT);
    var pStop = useRef<TPos>(INIT);

    const swipeStart = (e: any) => {
        if (typeof e["targetTouches"] !== "undefined") {
            var touch = e.targetTouches[0];
            pStart.current = { x: touch.clientX, y: touch.clientY };
        } else {
            pStart.current = { x: e.clientX, y: e.clientY };
        }
    }

    const swipeEnd = (e: any) => {
        if (typeof e["changedTouches"] !== "undefined") {
            var touch = e.changedTouches[0];
            pStop.current = { x: touch.clientX, y: touch.clientY };
        } else {
            pStop.current = { x: e.clientX, y: e.clientY };
        }

        swipeCheck();
    }

    const swipeCheck = () => {
        var changeY = pStart.current.y - pStop.current.y;
        var changeX = pStart.current.x - pStop.current.x;
        switch (mode) {
            case MODE.USE_PULL_RIGHT:
                if (isPullRight(changeY, changeX)) {
                    onTrigger();
                }
                break;
            case MODE.USE_SWIPE_LEFT:
                if (isSwipeLeft(changeY, changeX)) {
                    onTrigger();
                }
                break;
            default:
                return null
        }
    }

    const isPullRight = (dY: number, dX: number) => {
        // methods of checking slope, length, direction of line created by swipe action
        return (
            dX < 0 && pStart.current.x < 50 &&
            ((Math.abs(dY) <= 50 && Math.abs(dX) >= 100) ||
                (Math.abs(dY) / Math.abs(dX) <= 0.15 && dX <= 50))
        );
    }

    const isSwipeLeft = (dY: number, dX: number) => {
        // methods of checking slope, length, direction of line created by swipe action
        return (
            dX > 0 &&
            ((Math.abs(dY) <= 50 && Math.abs(dX) >= 75) ||
                (Math.abs(dY) / Math.abs(dX) <= 0.15 && dX >= 50))
        );
    }

    useEventListener(window, "touchstart", (e: any) => swipeStart(e));
    useEventListener(window, "touchend", (e: any) => swipeEnd(e));
}

// Triggers function when user swipes the left edge of the screen to the right
const usePullRight = (onTrigger: Function) => useSwipe(MODE.USE_PULL_RIGHT, onTrigger);
// Triggers function when user swipes left on the screen
const useSwipeLeft = (onTrigger: Function) => useSwipe(MODE.USE_SWIPE_LEFT, onTrigger);

export { usePullRight, useSwipeLeft };