// based on https://github.com/ReactiveX/rxjs/

import { System } from "../Reusable";
import { IDisposable, isDisposable } from "../adapters/fable/Util";

// export interface Unsubscribable {
//     unsubscribe(): void;
// }
// export interface Subscribable<T> {
//     subscribe(observer: Partial<Observer<T>>): Unsubscribable;
// }

// not secure, not being used for anything secure either
// export function generateGuid(): string {
//     try {
//         return crypto.randomUUID();
//     } catch (ex) {
//         console.warn(ex);
//         return Math.random().toString(36).substring(2, 9);
//     }
// }

export function generateGuid(): string {
    return Math.random().toString(36).substring(2, 9);
}

export interface Unsubscriber {
    (): void;
}

export interface IObservable<T> {
    Subscribe(action: WeakRef<System.Action1<T>>, debugTitle?: string): Unsubscriber;
}

export interface IObservableValue<T> extends IObservable<T> {
    value: T
}

// weakness: does not check T, only that it has subscribe
export function isObservable<T>(x: T | IObservable<T>): x is IObservable<T> {
    return x != null && typeof (x as IObservable<T>).Subscribe === "function";
}

type WeakRefWrapper<T> = {
    key: string
    value: WeakRef<System.Action1<T>>
}

// we take a function that fetches a fresh promise to get T
// should this concern itself with inflight?
// export class Observable<T> implements IObservable<T>{
//     private readonly _future: Func<Promise<T>>;
//     private _observers: WeakRefWrapper<T>[] = [];

//     constructor(future: Func<Promise<T>>) {
//         this._future = future;
//     }

//     public async Refresh(): Promise<T> {
//         let value = await this._future();
//         this._observers.map(x => {
//             let f = x.value.deref()
//             if (!!f) {
//                 f(value);
//             }
//         })
//         return value;
//     }

//     public Subscribe(action: WeakRef<Action1<T>>): Unsubscriber {
//         let key = generateGuid();
//         this._observers.push({ key, value: action });
//         return () => {
//             let i = this._observers.findIndex(v => v.key == key)
//             if (i >= 0)
//                 this._observers.splice(i, 1)
//         }
//     }
// }
export class Observable<T> implements IObservable<T> {

    private _debug: boolean;
    private _observers: WeakRefWrapper<T>[] = [];

    constructor(debug?: boolean) {
        this._debug = debug || false;
    }

    private tryRemoveByKey(key: string) {
        if (this._debug) {
            console.log("disposing:" + key);
        }
        let i = this._observers.findIndex(v => v.key == key)
        if (i >= 0) {
            if (this._debug)
                console.log("Found disposable key");
            this._observers.splice(i, 1)
        }
    }

    public Next = (value: T) => {
        this._observers.map(x => {
            let f = x.value.deref()
            if (f) {
                // console.log('calling observer', x.key);
                f(value);
            } else {
                // console.log(x.key + ' lost weak ref');
                this.tryRemoveByKey(x.key);
            }
        });
    }

    public Subscribe(action: WeakRef<System.Action1<T>>, debugTitle?: string): Unsubscriber & { key: string } {
        let key = generateGuid();
        this._observers.push({ key, value: action });
        if (debugTitle && debugTitle.length > 0) {
            console.log('created subscription', JSON.stringify({ key, title: debugTitle }));
        }

        var unSub = () => {
            if (debugTitle && debugTitle.length > 0) {
                console.log('disposing', JSON.stringify({ key, title: debugTitle }));
            }
            this.tryRemoveByKey(key);
        }
        (unSub as any).key = key;
        return unSub as Unsubscriber & { key: string };
    }
}

// we need the abstract idea of an observable that has a current or initial value
export class ObservableValue<T> implements IObservable<T>, IObservableValue<T>, IDisposable {

    private _value: T;
    private _obs: Observable<T>;

    private _isDisposed: boolean = false;

    constructor(value: T, debug?: boolean) {
        this._value = value;
        this._obs = new Observable<T>(debug);

    }

    public get value() { return this._value; }
    public get isDisposed() { return this._isDisposed; }

    public set value(next: T) {

        if (this._isDisposed) return;
        this._obs.Next(next);
    }

    public Subscribe(action: WeakRef<System.Action1<T>>) {
        return this._obs.Subscribe(action);
    }

    public Next(value: T) {
        this._obs.Next(value);
    }

    public Dispose() {
        if (this._isDisposed) return;
        this._isDisposed = true;
        if ('Dispose' in this._obs) {
            (this._obs as IDisposable).Dispose();
        }
    }
}

export class ObservablePromise<T> implements IObservable<T>, IDisposable {
    private readonly _future: System.Func<Promise<T>>;
    private _observers: WeakRefWrapper<T>[] = [];
    private _isDisposed: boolean = false;
    private _disposal: Unsubscriber | undefined;
    private _value: T;
    private _debug: boolean;

    constructor(value: T, future: System.Func<Promise<T>>, debug: boolean) {
        this._debug = debug;
        this._future = future;
        this._value = value;
        if (isObservable(this._value)) {
            this._disposal = this._value.Subscribe(new WeakRef((value: T) => this._value = value));
        }
    }

    public get value() { return this._value; }
    public get IsDisposed() { return this._isDisposed; }

    public set value(next: T) {

        if (this._isDisposed) return;

        console.log('setting value in observable value');
        this._value = next;
        this._observers.map(x => {
            let f = x.value.deref()
            if (f) {
                console.log('calling observer', x.key);
                f(this._value);
            }
        });
    }

    public async Refresh(): Promise<T> {
        if (this._isDisposed) throw new Error("Observable Value is disposed");
        let next = await this._future();
        // is this how you access a setter in typescript?
        this.value = next;
        return this._value;
    }

    public Subscribe(action: WeakRef<System.Action1<T>>): Unsubscriber & { key: string } {
        if (this._isDisposed) throw new Error("Observable Value is disposed");

        let key = generateGuid();
        this._observers.push({ key, value: action });
        var unSub = () => {
            if (this._debug) {
                console.log("disposing:" + key);
            }
            let i = this._observers.findIndex(v => v.key == key)
            if (i >= 0) {
                if (this._debug)
                    console.log("Found disposable key");
                this._observers.splice(i, 1)
            }
        }
        (unSub as any).key = key;
        return unSub as Unsubscriber & { key: string };
    }

    public Dispose() {
        if (this._disposal) {
            if (isDisposable(this._disposal)) {
                this._disposal.Dispose();
            } else this._disposal();
            this._disposal = undefined;
        }
        this._isDisposed = true;
    }
}

